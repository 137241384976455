import { Controller } from "stimulus"
import Rails from "@rails/ujs"


export default class extends Controller {
  static targets = [ 'keyword', 'canvas' ]

  static values = {
    path: String
  }

  connect() {
  }

  async searchUsers() {
    let keyword = this.keywordTarget.value
    if (keyword.length <= 2) {
      this.canvasTarget.innerHTML = ''
      return
    }

    const params = {
      'q[uri_or_email_or_name_cont]': keyword
    }

    const query = new URLSearchParams(params)
    const response = await fetch(`${this.pathValue}?${query}`)

    this.canvasTarget.innerHTML = await response.text()
  }
}
