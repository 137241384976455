import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import Masonry from 'masonry-layout'
import ImagesLoaded from 'imagesloaded'
import * as lib from 'javascripts/concerns'

export default class extends Controller {
  static targets = [ 'form', 'fileField', 'progressBar', 'masonry' ]

  static values = {
  }

  connect() {
    this.masonryUp()
  }

  upload() {
    Rails.fire(this.formTarget, 'submit')

    this.fileFieldTarget.disabled = true
    this.progressBarTarget.parentNode.classList.replace('d-none', 'd-flex')
    this.progressBarTarget.classList.add('bg-primary')
    this.progressBarTarget.classList.remove('bg-danger')
    this.progressBarTarget.style.width = '0px'
  }

  uploadBeforeSend() {
    event.detail[0].upload.addEventListener('progress', e => {
      let progress = parseInt((e.loaded / e.total) * 100)
      this.progressBarTarget.style.width = `${progress}%`
    })
  }

  uploadedWithSuccess() {
    this.progressBarTarget.classList.add('bg-success')
  }

  uploadedWithError() {
    this.fileFieldTarget.disabled = false
    this.progressBarTarget.classList.replace('bg-primary', 'bg-danger')
  }

  masonryUp() {
    this.masonry = new Masonry(this.masonryTarget, {
      itemSelector: '.card'
    })

    ImagesLoaded(this.masonryTarget, () => {
      this.masonry.layout()
    })
  }

  destroyed() {
    let removing_col = event.target.closest('.col').remove()
    this.masonry.remove(removing_col)
    this.masonryUp()
  }
}
