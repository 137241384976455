import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import 'ace-builds'
import 'ace-builds/webpack-resolver'
import * as lib from 'javascripts/concerns'


export default class extends Controller {
  static targets = [ 'menu', 'editor', 'textarea', 'changedMessage' ]

  static values = {
    mode: String
  }

  connect() {
    this.editorChanged = false

    this.ace = ace.edit('editor', {
      mode:     this.modeValue,
      theme:    'ace/theme/xcode',
      fontSize: 14,
      tabSize:  2,
      showInvisibles: true
    });

    this.ace.addEventListener('change', (delta) => {
      if (!this.editorChanged) {
        this.changedMessageTarget.classList.replace('d-none', 'd-block')
        window.addEventListener('beforeunload', this.leaveWarning)
      }
      this.editorChanged = true
    })

    this.reheight()
    this.inputDataToEditor()
    this.clearEditorChanged()

    window.addEventListener('resize', () => {
      this.reheight()
    })
  }

  inputDataToEditor() {
    this.changedMessageTarget.classList.add('d-none')
    this.ace.setValue(this.textareaTarget.value)
  }

  iputDataToTextarea() {
    this.textareaTarget.value = this.ace.getValue()
  }

  clearEditorChanged() {
    this.editorChanged = false
    this.changedMessageTarget.classList.replace('d-block', 'd-none')
    window.removeEventListener('beforeunload', this.leaveWarning)
  }

  reheight() {
    const out_height = Array.from(document.querySelectorAll('body > *:not(body > #main)')).map(dom => {
      let height = dom.offsetHeight
      let { top, right, bottom, left } = lib.elementMargin(dom)

      return height + top + bottom
    }).reduce((sum, num) => sum + num)

    const editor_height = window.innerHeight - out_height - 10

    const submit = this.element.querySelector('form [data-for-height-calc]')
    let { top, right, bottom, left } = lib.elementMargin(submit)

    this.menuTarget.style.height = (editor_height) + 'px';
    this.editorTarget.style.height = (editor_height - top - bottom - 30) + 'px';
  }

  leaveWarning(event) {
    event.preventDefault()
    event.returnValue = 'Check'
  }
}
