import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = []

  static values = {
  }

  connect() {
  }

  redirect() {
    window.location.href = event.target.value
  }
}
