import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ 'show', 'edit', 'nameForm' ]

  static values = {
  }

  connect() {
    this.showTarget.classList.add('d-block')
    this.showTarget.classList.remove('d-none')
    this.editTarget.classList.add('d-none')
    this.editTarget.classList.remove('d-block')
  }

  update() {
    this.scoping_event = event

    let formData = new FormData()
    formData.append('_method', 'patch')
    formData.append('category[name]', this.nameFormTarget.value)

    fetch(event.target.getAttribute('href'), {
      body: formData,
      method: 'POST',
      credentials: 'include',
      headers: {
        'X-CSRF-Token': Rails.csrfToken()
      }
    }).then(response => {
      if (!response.ok) {
        throw new Error(response.json())
      }
      Rails.fire(this.scoping_event.target, 'ajax:success')
      this.scoping_event.preventDefault()
    }).catch(error => {
    })

    event.preventDefault()
  }

  showForm() {
    this.showTarget.classList.replace('d-block', 'd-none')
    this.editTarget.classList.replace('d-none', 'd-block')

    event.preventDefault()
  }

  hideForm() {
    this.showTarget.classList.replace('d-none', 'd-block')
    this.editTarget.classList.replace('d-block', 'd-none')

    event.preventDefault()
  }
}
