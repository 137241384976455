export function addToast(data) {
  if (!data || !data.title || !data.state) {
    console.log('data.titleまたはdata.stateが存在しないためtoastしない')
    return
  }
  if (!data.delay) {
    data.delay = 10000
  }

  let strong = document.createElement('strong')
  strong.classList.add('mr-auto')
  strong.appendChild(document.createTextNode(data.title))

  let button = document.createElement('button')
  button.classList.add('ml-2', 'mb-1', 'close')
  button.setAttribute('type', 'button')
  button.setAttribute('data-dismiss', 'toast')

  let span = document.createElement('span')
  span.innerHTML = '&times;'

  button.appendChild(span)

  let header = document.createElement('div')
  header.classList.add('toast-header')

  header.appendChild(strong)
  header.appendChild(button)

  let toast = document.createElement('div')
  toast.classList.add('toast', 'toast-' + data.state)
  toast.setAttribute('data-delay', data.delay)

  toast.appendChild(header)

  if (data.message) {
    let body = document.createElement('div')
    body.classList.add('toast-body', 'text-muted')
    body.innerHTML = data.message.replace(/\r?\n/g, '<br />')

    toast.appendChild(body)
  }

  const wrapper = document.getElementById('toast-wrapper')
  wrapper.appendChild(toast)

  toast('show')
}

export function elementMargin(element) {
  const styles = window.getComputedStyle(element);
  return ['top', 'right', 'bottom', 'left'].reduce((obj, key) => {
    return {
      ...obj,
      [key]: parseFloat(styles.getPropertyValue(`margin-${key}`)) || 0,
    }
  }, 0)
}
