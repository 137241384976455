import { Controller } from "stimulus"
import Rails from "@rails/ujs"


export default class extends Controller {
  static targets = [ 'form', 'canvas', 'field' ]

  static values = {
    path: String
  }

  connect() {
    this.load()
  }

  load() {
    Rails.fire(this.formTarget, 'submit')
  }

  draw() {
    this.canvasTarget.innerHTML = event.detail[2].response
  }
}
