import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = []
  static values = {
    path: String
  }

  connect() {
    this.load()
  }

  load() {
    console.log('load')

    fetch(this.pathValue, {
    }).then(
      response => response.text()
    ).then(html => {

      this.element.innerHTML = html
    })
  }
}
