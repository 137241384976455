import { Controller } from "stimulus"
import { Toast } from 'bootstrap'


export default class extends Controller {
  static targets = [ "flash" ]

  add() {
    if (event.detail) {
      this.perform(event.detail[0])
    }
  }

  addByFlash() {
    let dataset, data
    this.flashTargets.forEach((element, index) => {
      data = {
        state:   element.dataset.state,
        title:   element.dataset.title,
        message: element.dataset.message,
        delay:   element.dataset.delay
      }
      this.perform(data)
    })
  }

  perform(data) {
    if (!data || !data.title || !data.state) {
      console.log('data.titleまたはdata.stateが存在しないためtoastしない')
      return
    }
    if (!data.delay) {
      data.delay = 10000
    }

    let svg = document.createElementNS("http://www.w3.org/2000/svg", 'svg')
    svg.classList.add('bd-placeholder-img', 'rounded', 'me-2')
    svg.setAttribute('width', '18')
    svg.setAttribute('height', '18')
    svg.innerHTML = '<rect width="100%" height="100%" fill="' + this.colorSchema()[data.state] + '"></rect>';


    let strong = document.createElement('strong')
    strong.classList.add('me-auto', 'text-' + data.state)
    strong.appendChild(document.createTextNode(data.title))

    let button = document.createElement('button')
    button.classList.add('btn-close')
    button.setAttribute('data-bs-dismiss', 'toast')
    button.setAttribute('type', 'button')

    let header = document.createElement('div')
    header.classList.add('toast-header')

    header.appendChild(svg)
    header.appendChild(strong)
    header.appendChild(button)

    let toast = document.createElement('div')
    toast.classList.add('toast')
    toast.setAttribute('data-bs-delay', data.delay)

    toast.appendChild(header)

    if (data.message) {
      let body = document.createElement('div')
      body.classList.add('toast-body', 'bg-white', 'text-muted')
      data.message.split("\n").forEach(message => {
        let mp = document.createElement('p')
        mp.appendChild(document.createTextNode(message))
        body.appendChild(mp)
      })

      toast.appendChild(body)
    }

    const wrapper = document.getElementById('toast-canvas')
    wrapper.appendChild(toast)

    const toaster = new Toast(toast)
    toaster.show()
  }

  colorSchema() {
    return {
      primary:    '#0d6efd',
      secondary:  '#6c757d',
      success:    '#198754',
      info:       '#0dcaf0',
      warning:    '#ffc107',
      danger:     '#dc3545',
      light:      '#f8f9fa',
      dark:       '#212529'
    }
  }
}
