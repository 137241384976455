import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ 'nameForm' ]

  static values = {
  }

  connect() {
  }

  create() {
    let formData = new FormData()
    formData.append('category[name]', this.nameFormTarget.value)

    fetch(event.target.getAttribute('href'), {
      body: formData,
      method: 'POST',
      credentials: 'include',
      headers: {
        'X-CSRF-Token': Rails.csrfToken()
      }
    }).then(response => {
      if (!response.ok) {
        throw new Error(response.json())
      }
    }).catch(error => {
    })

    event.preventDefault()
  }
}
