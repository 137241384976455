import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ 'canvas' ]

  static values = {
    path:     String,
    name:     String,
    selected: String
  }

  connect() {
    this.load()
  }

  async load() {
    if (!this.hasPathValue || !this.hasNameValue || !this.hasSelectedValue) {
      console.error('fail select tag')
      return
    }

    const params = {
      name:     this.nameValue,
      selected: this.selectedValue
    }
    const query = new URLSearchParams(params)
    const response = await fetch(`${this.pathValue}?${query}`)

    this.canvasTarget.innerHTML = await response.text()

    let select_tag = this.canvasTarget.querySelector('select')
    if (!select_tag) {
      return
    }

    select_tag.setAttribute('data-sites--articles--prev-article-target', 'categoryId')
  }
}
