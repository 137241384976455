import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ ]


  connect() {
    document.addEventListener('change', this)
  }

  disconnect() {
    document.removeEventListener('change', this)
  }

  handleEvent(event) {
    switch(event.type) {
      case 'change':
        this.draw(event)
        break
    }
  }

  draw(event) {
    const self  = this
    const files = this.element.files
    let canvas  = document.querySelector(this.data.get('canvas'))

    if (canvas.length == 0) {
      return
    }

    if (files.length > 0) {
      let file   = files[0]
      let reader = new FileReader()
      reader.onerror = function() {
        alert('画像読み込み失敗')
      }
      reader.onload = function() {
        let img = document.createElement('img')
        img.setAttribute('src', reader.result)
        img.setAttribute('class', 'img-fluid')

        canvas.innerHTML = ''
        canvas.appendChild(img)
      }
      reader.readAsDataURL(file)
    }
  }
}
