// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import 'controllers'
import 'bootstrap'

Rails.start()
ActiveStorage.start()

const images = require.context('images', true)
const imagePath = (name) => images(name, true)

require("trix")
require("@rails/actiontext")

import 'bootstrap';
import '@fortawesome/fontawesome-free/js/all';

import '../stylesheets/application.css';
