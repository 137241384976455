import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [
    'title', 'summary', 'image', 'categoryId', 'publishedAt', 'html'
  ]

  static values = {
    path: String
  }

  connect() {
  }

  upload() {
    let scoping_event = event

    let formData = new FormData()
    formData.append('_method', 'patch')
    formData.append(this.titleTarget.name,      this.titleTarget.value)
    formData.append(this.summaryTarget.name,    this.summaryTarget.value)
    formData.append(this.categoryIdTarget.name, this.categoryIdTarget.value)
    formData.append(this.htmlTarget.name,       this.htmlTarget.value)

    this.publishedAtTargets.forEach(target => {
      formData.append(target.name, target.value)
    })

    if (this.imageTarget.files[0]) {
      formData.append('article[image]', this.imageTarget.files[0])
    }

    fetch(this.pathValue, {
      body: formData,
      method: 'POST',
      credentials: 'include',
      headers: {
        'X-CSRF-Token': Rails.csrfToken()
      }
    }).then(response => {
      if (!response.ok) {
        throw new Error(response.json())
      }

      window.open(scoping_event.target.getAttribute('href'))
    }).catch(error => {
    })

    event.preventDefault()
  }
}
