import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ 'text', 'canvas', 'previewButton', 'editButton' ]

  static values = {
  }

  connect() {
  }

  render() {
   let formData = new FormData()
    formData.append('markdown[text]', this.textTarget.value)

    fetch(event.target.getAttribute('href'), {
      body: formData,
      method: 'POST',
      credentials: 'include',
      headers: {
        'X-CSRF-Token': Rails.csrfToken()
      }
    }).then(response => {
      if (!response.ok) {
        throw new Error(response.text())
      }
      return response.text()
    }).then(html => {
      console.log(html)
      this.canvasTarget.innerHTML = html

      this.previewButtonTarget.classList.replace('d-inline', 'd-none')
      this.editButtonTarget.classList.replace('d-none', 'd-inline')

      event.preventDefault()
    }).catch(error => {
    })

    event.preventDefault()
  }

  edit() {
    this.previewButtonTarget.classList.replace('d-none', 'd-inline')
    this.editButtonTarget.classList.replace('d-inline', 'd-none')

    event.preventDefault()
  }
}
